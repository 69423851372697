var h = Object.defineProperty;
var u = (n, t, e) => t in n ? h(n, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : n[t] = e;
var c = (n, t, e) => (u(n, typeof t != "symbol" ? t + "" : t, e), e);
import f, { Component as m, createElement as v, Children as b } from "react";
const p = (n, t) => typeof getComputedStyle < "u" ? getComputedStyle(n, null).getPropertyValue(t) : n.style.getPropertyValue(t), y = (n) => p(n, "overflow") + p(n, "overflow-y") + p(n, "overflow-x"), w = (n) => {
  if (!(n instanceof HTMLElement))
    return window;
  let t = n;
  for (; t && !(t === document.body || t === document.documentElement || !t.parentNode); ) {
    if (/(scroll|auto)/.test(y(t)))
      return t;
    t = t.parentNode;
  }
  return window;
};
class g extends m {
  constructor(e) {
    super(e);
    c(this, "elementObserver");
    c(this, "wrapper");
    c(this, "lazyLoadHandler", (e) => {
      var o, i;
      const {
        onContentVisible: s
      } = this.props, [r] = e, {
        isIntersecting: l
      } = r;
      if (l) {
        this.setState({
          visible: !0
        }, () => {
          s && s();
        });
        const a = (o = this.wrapper) == null ? void 0 : o.current;
        a && a instanceof HTMLElement && ((i = this.elementObserver) == null || i.unobserve(a));
      }
    });
    this.elementObserver = null, this.wrapper = f.createRef(), this.state = {
      visible: !1
    };
  }
  componentDidMount() {
    var o;
    this.getEventNode();
    const {
      offset: e,
      threshold: s
    } = this.props, r = {
      rootMargin: typeof e == "number" ? `${e}px` : e || "0px",
      threshold: s || 0
    };
    this.elementObserver = new IntersectionObserver(this.lazyLoadHandler, r);
    const l = (o = this.wrapper) == null ? void 0 : o.current;
    l instanceof HTMLElement && this.elementObserver.observe(l);
  }
  shouldComponentUpdate(e, s) {
    return s.visible;
  }
  componentWillUnmount() {
    var s, r;
    const e = (s = this.wrapper) == null ? void 0 : s.current;
    e && e instanceof HTMLElement && ((r = this.elementObserver) == null || r.unobserve(e));
  }
  getEventNode() {
    var e;
    return w((e = this.wrapper) == null ? void 0 : e.current);
  }
  render() {
    const {
      children: e,
      className: s,
      height: r,
      width: l,
      elementType: o
    } = this.props, {
      visible: i
    } = this.state, a = {
      height: r,
      width: l
    }, d = `LazyLoad${i ? " is-visible" : ""}${s ? ` ${s}` : ""}`;
    return v(o || "div", {
      className: d,
      style: a,
      ref: this.wrapper
    }, i && b.only(e));
  }
}
c(g, "defaultProps", {
  elementType: "div",
  className: "",
  offset: 0,
  threshold: 0,
  width: null,
  onContentVisible: null,
  height: null
});
export {
  g as default
};
